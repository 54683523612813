import React, { useEffect, useState, useRef, useCallback } from 'react';
import './App.css';
import FallingPictures from './FallingPictures';

function App() {
  const [showPanel, setShowPanel] = useState(true);
  const [showFalling, setShowFalling] = useState(false);
  const audioContextRef = useRef(null);
  const soundBufferRef = useRef(null);
  const musicSourceRef = useRef(null);
  const musicBufferRef = useRef(null);
  
  // Initialize audio context and load sound
  useEffect(() => {
    // Create audio context
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContextRef.current = new AudioContext();
    
    // Load sound file
    fetch('/sound14.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContextRef.current.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        soundBufferRef.current = audioBuffer;
      })
      .catch(error => console.error('Error loading sound:', error));
      
    // Load music file (riri.mp3)
    fetch('/riri.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContextRef.current.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        musicBufferRef.current = audioBuffer;
      })
      .catch(error => console.error('Error loading music:', error));
      
    // Cleanup
    return () => {
      // Stop any playing music
      if (musicSourceRef.current) {
        try {
          musicSourceRef.current.stop();
        } catch (e) {
          // Ignore errors if already stopped
        }
      }
      
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close();
      }
    };
  }, []);
  
  // Function to play sound
  const playSound = useCallback(() => {
    if (!audioContextRef.current || !soundBufferRef.current) return;
    
    console.log("Playing sound14", audioContextRef.current.state);
    
    try {
      // Create source node
      const source = audioContextRef.current.createBufferSource();
      source.buffer = soundBufferRef.current;
      
      // Create gain node to control volume
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 0.7; // Set to 70% volume
      
      // Connect source -> gain -> destination
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      // Play sound
      source.start(0);
      console.log("Sound started");
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  }, []);
  
  // Function to play music in loop
  const playMusic = useCallback(() => {
    if (!audioContextRef.current || !musicBufferRef.current) return;
    
    console.log("Playing music", audioContextRef.current.state);
    
    try {
      // Stop previous music if playing
      if (musicSourceRef.current) {
        try {
          musicSourceRef.current.stop();
        } catch (e) {
          // Ignore errors if already stopped
        }
      }
      
      // Create source node
      const source = audioContextRef.current.createBufferSource();
      source.buffer = musicBufferRef.current;
      source.loop = true; // Enable looping
      
      // Create gain node to control volume
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 0.1; // Reduce to 10% of original volume
      
      // Connect source -> gain -> destination
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      // Store reference to stop later if needed
      musicSourceRef.current = source;
      
      // Play music
      source.start(0);
      console.log("Music started");
    } catch (error) {
      console.error("Error playing music:", error);
    }
  }, []);
  
  useEffect(() => {
    // Generate random stars
    const generateStars = () => {
      const stars = document.getElementById('stars-container');
      stars.innerHTML = '';
      
      // Reduced star count
      const starCount = 40;
      for (let i = 0; i < starCount; i++) {
        const star = document.createElement('div');
        star.classList.add('star');
        
        // Random position
        const left = Math.random() * 100;
        const top = Math.random() * 100;
        
        // Star size - increased for star shapes
        const size = Math.random() * 20 + 12;
        
        // Random color - bolder and stronger colors
        const colors = [
          '#ffffff', // White
          '#ffff00', // Bright yellow
          '#ff4500', // Orange red
          '#ff00ff', // Magenta
          '#00ffff', // Cyan
          '#ff1493', // Deep pink
          '#7b68ee', // Medium slate blue
          '#00ff7f', // Spring green
          '#ff6347', // Tomato
          '#1e90ff'  // Dodger blue
        ];
        const color = colors[Math.floor(Math.random() * colors.length)];
        
        // Set star properties
        star.style.left = `${left}%`;
        star.style.top = `${top}%`;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        
        // Use the color as a custom property instead of direct backgroundColor
        star.style.setProperty('--star-color', color);
        
        // Random animation delay
        star.style.animationDelay = `${Math.random() * 5}s`;
        
        stars.appendChild(star);
      }
    };
    
    generateStars();
    
    // Create heart effect at touch position
    const createHeartEffect = (e) => {
      // If the tap happens on a falling picture or on the explore button, let them handle their own events
      if (e.target.classList.contains('falling-pic') || e.target.classList.contains('panel-button')) {
        return;
      }
      
      // Get position for heart effect
      let x, y;
      
      // Get touch coordinates
      if (e.touches && e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
      
      if (!x || !y) return; // Exit if coordinates aren't valid
      
      // Create multiple hearts for a burst effect
      const numHearts = Math.floor(Math.random() * 5) + 3; // 3-7 hearts
      
      for (let i = 0; i < numHearts; i++) {
        // Create a heart element
        const heart = document.createElement('div');
        heart.classList.add('heart');
        
        // Offset each heart slightly for a burst effect
        const offsetX = (Math.random() - 0.5) * 30;
        const offsetY = (Math.random() - 0.5) * 30;
        
        heart.style.left = `${x + offsetX}px`;
        heart.style.top = `${y + offsetY}px`;
        
        // Random size variation
        const scale = Math.random() * 0.6 + 0.7; // 0.7-1.3
        heart.style.transform = `scale(${scale})`;
        
        // Random heart colors
        const colors = ['#ff6b9d', '#ff4081', '#ec407a', '#e91e63', '#ff80ab'];
        heart.style.setProperty('--heart-color', colors[Math.floor(Math.random() * colors.length)]);
        
        // Random animation duration
        heart.style.animationDuration = `${Math.random() * 0.5 + 1}s`;
        
        // Append to app
        document.querySelector('.App').appendChild(heart);
        
        // Remove heart after animation completes
        setTimeout(() => {
          if (heart.parentNode) {
            heart.parentNode.removeChild(heart);
          }
        }, 1500);
      }
    };
    
    // Add heart effect listener - touch only
    document.addEventListener('touchstart', createHeartEffect);
    
    // Clean up
    return () => {
      document.removeEventListener('touchstart', createHeartEffect);
    };
  }, []);
  
  // Modify handleExploreClick to play music immediately
  const handleExploreClick = useCallback((e) => {
    // Play sound and music IMMEDIATELY on user interaction - BEFORE any UI changes
    // This is critical for iOS audio to work
    
    // 1. First make sure audio context is active (direct user action)
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'suspended') {
        // Resume context immediately on click/touch
        audioContextRef.current.resume().then(() => {
          console.log("Audio context resumed in handleExploreClick");
          // Play sounds only after context is confirmed running
          playSound();
          playMusic();
          
          // Now handle UI changes
          hidePanel();
        }).catch(err => {
          console.error("Failed to resume audio context:", err);
          // Still try to update UI even if audio fails
          hidePanel();
        });
      } else {
        // Context already running, play directly
        playSound();
        playMusic();
        hidePanel();
      }
    } else {
      // No audio context, just update UI
      hidePanel();
    }
    
    // Hide panel function - separated to avoid duplicating code
    function hidePanel() {
      // Add the panel-hide class to trigger the fade-out animation
      const panelContainer = document.querySelector('.panel-container');
      if (panelContainer) {
        panelContainer.classList.add('panel-hide');
        
        // Wait for the animation to complete before hiding the panel
        setTimeout(() => {
          setShowPanel(false);
          // Show falling pictures after panel is hidden
          setTimeout(() => {
            setShowFalling(true);
          }, 100);
        }, 800); // Match the fadeOut animation duration
      } else {
        // Fallback if panel container not found
        setShowPanel(false);
        setTimeout(() => {
          setShowFalling(true);
        }, 800);
      }
    }
  }, [playMusic, playSound]);
  
  // Add a touch handler for the button to prevent long-press issues
  useEffect(() => {
    // Reference to the click listener function
    const clickListener = (e) => {
      console.log("Button clicked - direct user interaction");
      
      // Handle audio directly on user interaction - critical for iOS
      if (audioContextRef.current) {
        // First, directly resume the audio context on user gesture
        // This is the key for iOS audio to work
        audioContextRef.current.resume()
          .then(() => {
            console.log("Audio context resumed directly from user gesture");
            
            // Now we can directly play audio, since playSound and playMusic
            // no longer have internal async code
            playSound();
            playMusic();
          })
          .catch(err => {
            console.error("Failed to resume audio context:", err);
          });
      }
      
      // Add visual feedback immediately
      const button = e.currentTarget;
      button.classList.add('touch-active');
      button.classList.add('clicked');
      
      // Handle UI animations
      const panelContainer = document.querySelector('.panel-container');
      if (panelContainer) {
        panelContainer.classList.add('panel-hide');
        
        // Wait for the animation to complete before hiding the panel
        setTimeout(() => {
          setShowPanel(false);
          // Show falling pictures after panel is hidden
          setTimeout(() => {
            setShowFalling(true);
          }, 100);
        }, 800); // Match the fadeOut animation duration
      } else {
        // Fallback if panel container not found
        setShowPanel(false);
        setTimeout(() => {
          setShowFalling(true);
        }, 800);
      }
      
      // Remove clicked class after animation completes
      setTimeout(() => {
        button.classList.remove('clicked');
        button.classList.remove('touch-active');
      }, 300);
    };
    
    // Find the button and add the touch handler
    const button = document.querySelector('.panel-button');
    if (button) {
      button.addEventListener('click', clickListener);
    }
    
    // Simple touchstart handler - just resume audio context
    const handleFirstTouch = () => {
      if (audioContextRef.current) {
        audioContextRef.current.resume().then(() => {
          console.log("Audio context resumed on first touch");
        }).catch(err => {
          console.error("Failed to resume audio context on first touch:", err);
        });
      }
      document.removeEventListener('touchstart', handleFirstTouch);
    };
    
    document.addEventListener('touchstart', handleFirstTouch, { once: true });
    
    // Cleanup
    return () => {
      if (button) {
        button.removeEventListener('click', clickListener);
      }
      document.removeEventListener('touchstart', handleFirstTouch);
    };
  }, [playSound, playMusic, setShowPanel, setShowFalling]);
  
  return (
    <div className="App">
      <div id="stars-container" className="stars-container"></div>
      
      {showPanel && (
        <div className={`panel-container ${!showPanel ? 'panel-hide' : ''}`}>
          <div className="panel">
            <h2>Bebouille</h2>
            <p>Joyeux anniversaire à nous 🌹</p>
            <button className="panel-button">Enter</button>
          </div>
        </div>
      )}
      
      {showFalling && <FallingPictures playSound={playSound} />}
      
      <div className="copyright-footer">
        official bebouille©️ website
      </div>
    </div>
  );
}

export default App;
