import React, { useEffect } from 'react';
import './App.css';

const FallingPictures = ({ playSound }) => {
  useEffect(() => {
    const TOTAL_IMAGES = 90; // Total available images in /public/mj folder
    const NEW_PIC_INTERVAL = 2000; // Create a new picture every 2 seconds
    
    const container = document.getElementById('falling-container');
    
    // Map of active touches to their elements
    const touchTracker = new Map();
    
    // Prevent default long-press behavior on container
    container.addEventListener('contextmenu', (e) => {
      if (e.target.classList.contains('falling-pic')) {
        e.preventDefault();
        return false;
      }
    });
    
    // Function to generate a single falling picture
    const generateFallingPicture = () => {
      // Create an image element
      const pic = document.createElement('img');
      pic.classList.add('falling-pic');
      
      // Select a random image from the folder
      const randomImageNumber = Math.floor(Math.random() * TOTAL_IMAGES) + 1;
      pic.src = `/mj/${randomImageNumber}.jpeg`;
      
      // Add loading and error handling
      pic.onerror = () => {
        pic.src = `/mj/1.jpeg`; // Fallback to first image if there's an error
      };
      
      // Random position (width)
      const left = Math.random() * 50 + 25;
      
      // Random falling speed
      const duration = Math.random() * 8 + 10; // Between 10-18 seconds to fall
      
      // Random rotation
      const rotation = Math.random() * 360;
      
      // Set falling pic properties
      pic.style.left = `${left}%`;
      pic.style.animationDuration = `${duration}s`;
      pic.style.animationDelay = '0s';
      pic.style.transform = `rotate(${rotation}deg)`;
      
      // Random size (width between 60px and 120px)
      const size = Math.random() * 160 + 160;
      pic.style.width = `${size}px`;
      pic.style.height = 'auto';
      
      // Add animation end listener to remove elements when they finish falling
      pic.addEventListener('animationend', () => {
        if (pic.parentNode && !pic.classList.contains('being-touched') && !pic.classList.contains('popping')) {
          pic.parentNode.removeChild(pic);
        }
      });
      
      container.appendChild(pic);
      return pic;
    };
    
    // Setup document-level touch event handlers
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchend', handleTouchEnd, { passive: false });
    document.addEventListener('touchcancel', handleTouchEnd, { passive: false });
    
    // Handle touch start
    function handleTouchStart(event) {
      // Process each new touch
      Array.from(event.changedTouches).forEach(touch => {
        const target = touch.target;
        
        // Unconditionally prevent default on all falling pictures to block zooming
        if (target.classList.contains('falling-pic')) {
          event.preventDefault();
          
          // Only proceed with touch handling if not already being touched or popping
          if (!target.classList.contains('being-touched') && !target.classList.contains('popping')) {
            // Add a unique touch identifier to the element for tracking
            target.dataset.touchId = touch.identifier;
            
            // Save the original transform for later restoration (on pop animation)
            const originalTransform = target.style.transform;
            target.dataset.originalTransform = originalTransform;
            
            // Get the current computed top position before removing animation
            const computedTop = window.getComputedStyle(target).top;
            
            // Completely remove the fall animation and set the current position
            target.style.animation = 'none';
            target.style.top = computedTop;
            
            // Clear any inline transform to allow CSS transitions to work
            target.style.transform = '';
            
            // Force a reflow before adding the being-touched class
            void target.offsetWidth;
            
            // Now add the being-touched class for the scale effect
            target.classList.add('being-touched');
            
            // Track this touch with its identifier
            touchTracker.set(touch.identifier, {
              element: target,
              originalTransform: originalTransform,
              touchTime: Date.now()
            });
          }
        }
      });
    }
    
    // Handle touch end or cancel
    function handleTouchEnd(event) {
      // Process each ended touch
      Array.from(event.changedTouches).forEach(touch => {
        const touchId = touch.identifier;
        
        // Check if we were tracking this touch
        if (touchTracker.has(touchId)) {
          const touchData = touchTracker.get(touchId);
          const pic = touchData.element;
          
          // Remove the touch tracking
          touchTracker.delete(touchId);
          
          // Only process if the element still exists and has our touch ID
          if (pic && pic.parentNode && pic.dataset.touchId === touchId.toString()) {
            // Play sound when releasing the image
            playSound();
            
            // Don't remove being-touched yet to keep the scale(2) state
            // Just add the popping class while the element is still enlarged
            pic.classList.add('popping');
            
            // Force a reflow to ensure animations work properly
            void pic.offsetWidth;
            
            // Now remove the being-touched class
            // The popping animation (which starts at scale(2)) will take over
            pic.classList.remove('being-touched');
            
            // Remove the element after the animation completes
            setTimeout(() => {
              if (pic.parentNode) {
                pic.parentNode.removeChild(pic);
              }
            }, 400);
          }
        }
      });
    }
    
    // Create first picture immediately
    generateFallingPicture();
    
    // Continuously generate new pictures at regular intervals
    const intervalId = setInterval(generateFallingPicture, NEW_PIC_INTERVAL);
    
    // Clean up event listeners when component unmounts
    return () => {
      clearInterval(intervalId);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, [playSound]);
  
  return <div id="falling-container" className="falling-container"></div>;
};

export default FallingPictures; 
